import React from 'react'
import Container from '../Container'
import Section from '../Section'


export const Footer = ({ children }) => (
  <Section>
    <Container>
      Copyright &copy; <a href="http://www.thebeat139.com">The Beat 139</a> 2018-{(new Date).getFullYear()}
    </Container>
  </Section>
);

export default Footer;
