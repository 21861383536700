import axios from 'axios';
import CONSTANTS from './constants';


export const API = axios.create({
  baseURL: 'http://localhost:3000/guests'
});

export const guestsOptions = () => {
  return async (dispatch) => {
    const res = await API.options('/');
    dispatch({ type: CONSTANTS.GUESTS_OPTIONS, payload: res.data });
  }
};

export const guestsList = () => {
  return async (dispatch, getState) => {
    const res = await API.get('/');
    dispatch({ type: CONSTANTS.GUESTS_LIST, payload: res.data });
  }
};

export const guestsGet = (uuid) => {
  return async (dispatch) => {
    const res = await API.get(`/${uuid}`);
    dispatch({ type: CONSTANTS.GUESTS_GET, payload: res.data });
  }
};

export const guestsDelete = (uuid) => {
  return async (dispatch) => {
    const res = await API.delete(`/${uuid}`);
    dispatch({ type: CONSTANTS.GUESTS_DELETE, payload: res.data });
  }
};

export const guestsPost = (data) => {
  return async (dispatch) => {
    const res = await API.post('/', data);
    dispatch({ type: CONSTANTS.GUESTS_POST, payload: res.data });
  }
};

export const guestsPatch = (uuid, data) => {
  return async (dispatch) => {
    const res = await API.patch(`/${uuid}`, data);
    dispatch({ type: CONSTANTS.GUESTS_PATCH, payload: res.data });
  }
};

export const guestsPut = (uuid, data) => {
  return async (dispatch) => {
    const res = await API.patch(`/${uuid}`, data);
    dispatch({ type: CONSTANTS.GUESTS_PUT, payload: res.data });
  }
};

export default {
  guestsOptions,
  guestsList,
  guestsPost,
  guestsGet,
  guestsPatch,
  guestsPut,
  guestsDelete
};
