import React from 'react'
import { FiSearch } from 'react-icons/fi'
import { Icon } from "react-bulma-components/full";
import { Field, Control, Input } from 'react-bulma-components/lib/components/form';


export const SearchBox = ({ keywords, onChange, placeholder="Search items", ...props }) => (
  <Field>
    <Control iconLeft>
      <Input type="text"
             value={keywords}
             onChange={onChange}
             placeholder={placeholder}
             size="large"
             color="info"
             { ...props } />
      <Icon align="left" icon="search">
        <FiSearch />
      </Icon>
    </Control>
  </Field>
);

export default SearchBox;
