import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';
import { Heading } from "react-bulma-components/full";


export const Text = ({ color, size, children, style={}, ...props }) => (
  <Heading size={size} color={color} style={{ ...style, color, fontFamily: 'Lexend Deca, Arial, sans-serif' }} {...props}>
    {children}
  </Heading>
);

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Text;
