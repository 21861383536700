import React from 'react'
import { Image, Pagination, Tile } from "react-bulma-components/full";
import InterviewCard from '../../components/InterviewCard'
import Layout from '../../components/Layout'
import SearchBox from '../../components/SearchBox'
import Section from '../../components/Section'
import Text from '../../components/Text'
import { data } from '../../libs/interviews'


const validData = data.filter(d => d['Guests'].length);

export const GuestCard = ({ guest }) => (
  guest ?
    <InterviewCard date={guest['Interview Date']}
                   title={guest['Guests']}
                   video={guest['Facebook Live']}
                   picture={`/images/guests/${guest['Image']}`}
                   instagram={guest['Instagram']}
                   facebook={guest['Facebook']}
                   twitter={guest['Twitter']} />
  : ''
);

export const GuestsView = () => {
  const ITEMS_PER_PAGE = 4*4;
  const [ state, setState ] = React.useState({ currentPage: 1, keywords: '' });
  const { currentPage, keywords } = state;
  let idx = (currentPage - 1) * ITEMS_PER_PAGE;
  const guests = keywords.length < 2 ? validData : validData.filter(d => (d['Guests']+d['instagram']).toLowerCase().indexOf(keywords.toLowerCase()) >= 0);
  const TOTAL_PAGES = Math.ceil(guests.length / ITEMS_PER_PAGE);

  return (
    <Layout title="Guest Appearances" subtitle="See who we've interviewed">
      <SearchBox placeholder="Search guests" keywords={keywords} onChange={e => { setState({ keywords: e.target.value, currentPage: 1 }) }} />
      <Pagination onChange={(page) => setState({ currentPage: page > TOTAL_PAGES ? 1 : page, keywords })}
                  className="is-rounded"
                  current={currentPage || 1}
                  total={TOTAL_PAGES}
                  delta={0}
                  showPrevNext={true} />
      <Section style={{ paddingLeft:0, paddingRight:0 }}>
        {!guests.length && <Text renderAs="p" size={5}>No guests found for "{keywords}"</Text>}
        <Tile kind="ancestor">
          <GuestCard guest={guests[idx]} />
          <GuestCard guest={guests[idx+1]} />
          <GuestCard guest={guests[idx+2]} />
          <GuestCard guest={guests[idx+3]} />
        </Tile>
        <Tile kind="ancestor">
          <GuestCard guest={guests[idx+4]} />
          <GuestCard guest={guests[idx+5]} />
          <GuestCard guest={guests[idx+6]} />
          <GuestCard guest={guests[idx+7]} />
        </Tile>
        <Tile kind="ancestor">
          <GuestCard guest={guests[idx+8]} />
          <GuestCard guest={guests[idx+9]} />
          <GuestCard guest={guests[idx+10]} />
          <GuestCard guest={guests[idx+11]} />
        </Tile>
        <Tile kind="ancestor">
          <GuestCard guest={guests[idx+12]} />
          <GuestCard guest={guests[idx+13]} />
          <GuestCard guest={guests[idx+14]} />
          <GuestCard guest={guests[idx+15]} />
        </Tile>
      </Section>
      <Pagination onChange={(page) => setState({ currentPage: page > TOTAL_PAGES ? 1 : page, keywords })}
                  className="is-rounded"
                  current={currentPage || 1}
                  total={TOTAL_PAGES}
                  delta={100}
                  showPrevNext={true} />
    </Layout>
  );
}

export default GuestsView;
