import React from 'react'
import { Container as BulmaContainer } from 'react-bulma-components/full';


export const Container = ({ children, fluid, breakpoint="mobile", ...props }) => (
  <BulmaContainer fluid={fluid} breakpoint={breakpoint} {...props}>
    {children}
  </BulmaContainer>
);

export default Container;
