import React from 'react'
import { Card } from "react-bulma-components/full";


// @see https://www.thewebtaylor.com/articles/how-to-get-a-youtube-videos-thumbnail-image-in-high-quality
const VIDEO_QUALITY = {
  'default': 'default',
  'hqdefault': 'hqdefault',
  'mqdefault': 'mqdefault',
  'sddefault': 'sddefault',
  'maxresdefault': 'maxresdefault',
}

export const YouTubeThumb = ({ videoId="IusGlFmYidc", quality="sddefault", ...props }) => (
  <Card.Image size="4by3" src={`https://i.ytimg.com/vi/${videoId}/${VIDEO_QUALITY[quality] || 'sddefault'}.jpg`} { ...props } />
);

export const YouTube = ({ videoId="cf_EXnUDAzU", title="Untitled", width=640, height=360, autoplay, ...props }) => (
  <iframe id="ytplayer" width={width} height={height} style={{ height, width }}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay ? "1" : "0"}&origin=http://example.com`}
          frameBorder="0" { ...props } />
);

export default YouTube;
