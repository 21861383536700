import React from 'react'
import { Modal } from "react-bulma-components/full";
import Text from '../Text'


const Popup = ({ title, footer, open=true, onClose, height='75%', width='75%', children, ...props }) => (
  <Modal closeOnBlur={true} showClose={true} show={open} onClose={onClose} { ...props }>
    <Modal.Card style={{height, width, maxWidth:1100}} onClick={onClose}>
      <Modal.Card.Head>
        <Modal.Card.Title renderAs="div">
          <Text size={3} weight="normal" style={{margin:0,width:'80%'}}>
            {title}
          </Text>
        </Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        { children }
      </Modal.Card.Body>
      {footer && <Modal.Card.Foot><Text size={4} weight="normal">{ footer }</Text></Modal.Card.Foot>}
    </Modal.Card>
  </Modal>
);

export default Popup;
