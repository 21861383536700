import React from 'react';
import { Card, Tile } from "react-bulma-components/full";
import { FiFacebook, FiInstagram, FiYoutube, FiTwitter } from 'react-icons/fi';
import Text from '../Text'
import DefaultPicture from './images/default.jpg'


export const InterviewCard = ({ date, title, video, picture, instagram, facebook, twitter, ...props }) => (
  <Tile kind="parent" size={3}>
    <Tile kind="child">
      <Card style={{ borderRadius:5,overflow:'hidden' }}>
        {/*<Card.Header>*/}
        {/*  <Card.Header.Title>*/}
        {/*    {guest['Interviews']}*/}
        {/*  </Card.Header.Title>*/}
        {/*</Card.Header>*/}
        <a target="_blank" href={video}>
          <Card.Image size="1by1" fallback={DefaultPicture} src={picture} alt={title} title={`The Beat 139 guest: ${title}`} {...props} />
        </a>
        <Card.Content style={{padding:10,paddingTop:0}}>
          <Text subtitle size={6}>{date}</Text>
          <Text size={5} weight="normal">
            <a target="_blank" href={video}>{title}</a>
          </Text>
        </Card.Content>
        <Card.Footer>
          {video &&
          <Card.Footer.Item renderAs="a" href={video} target="_blank">
            <FiYoutube size="1.25em" style={{color:'red',strokeWidth: 1}}/>
          </Card.Footer.Item>
          }
          {instagram &&
          <Card.Footer.Item renderAs="a" href={`https://www.instagram.com/${instagram}`} target="_blank">
            <FiInstagram size="1.25em" style={{color:'purple',strokeWidth:1}} />
          </Card.Footer.Item>
          }
          {facebook &&
          <Card.Footer.Item renderAs="a" href={facebook} target="_blank">
            <FiFacebook size="1.25em" style={{strokeWidth:1}} />
          </Card.Footer.Item>
          }
          {twitter &&
          <Card.Footer.Item renderAs="a" href={twitter} target="_blank">
            <FiTwitter size="1.25em" style={{strokeWidth:1}} />
          </Card.Footer.Item>
          }
        </Card.Footer>
      </Card>
    </Tile>
  </Tile>
);


export default InterviewCard;
