import React from 'react'
import Container from '../Container'
import Link from '../Link'
import Text from '../Text'
import { Level, Hero } from "react-bulma-components/full";
import Logo from './images/logo.jpg'
import styles from  './styles'


export const Header = ({ title="Untitled", subtitle, children }) => (
  <Hero color="black" gradient>
    <Hero.Body>
      <Container>
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <Link to="/">
                <img src={Logo} style={styles.logo} alt="TheBeat139 Podcast" />
              </Link>
              <div>
                <Text renderAs="h1" style={styles.title}>
                  <Link to="/">The Beat 139</Link>
                </Text>
                <Text renderAs="h2" subtitle size={4} weight="normal">
                  The #1 Podcast in Harlem!
                </Text>
              </div>
            </Level.Item>
          </Level.Side>
        </Level>
      </Container>
    </Hero.Body>
  </Hero>
);

export default Header;
