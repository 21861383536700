import React from 'react'
import Container from '../Container'
import Footer from '../Footer'
import Header from '../Header'
import Navigation from '../Navigation'
import Section from '../Section'
import Text from '../Text'
import { Hero } from "react-bulma-components/full";


export const Layout = ({ title="Untitled", subtitle, children }) => (
  <>
    <Header />
    <Navigation />
    <Hero color="white" xsize="medium" gradient>
      {/*<Hero.Head>*/}
      {/*  <Container>*/}
      {/*    <Text><br />{title}</Text>*/}
      {/*    {subtitle && <Text subtitle size={4}>{subtitle}</Text>}*/}
      {/*  </Container>*/}
      {/*</Hero.Head>*/}
      <Hero.Body>
        <Container>
          <Text>{title}</Text>
          {subtitle && <Text subtitle size={4}>{subtitle}</Text>}
        </Container>
      </Hero.Body>
    </Hero>
    <Section>
      <Container>
        <main>{children}</main>
      </Container>
    </Section>
    <Footer />
  </>
);

export default Layout;
