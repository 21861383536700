export const GUESTS_OPTIONS = 'GUESTS_OPTIONS';
export const GUESTS_LIST = 'GUESTS_LIST';
export const GUESTS_POST = 'GUESTS_POST';
export const GUESTS_GET = 'GUESTS_GET';
export const GUESTS_PATCH = 'GUESTS_PATCH';
export const GUESTS_PUT = 'GUESTS_PUT';
export const GUESTS_DELETE = 'GUESTS_DELETE';

export default {
  GUESTS_OPTIONS,
  GUESTS_LIST,
  GUESTS_POST,
  GUESTS_GET,
  GUESTS_PATCH,
  GUESTS_PUT,
  GUESTS_DELETE,
};
