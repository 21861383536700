import React from 'react'
import { Section as BulmaSection } from 'react-bulma-components/full';


export const Section = ({ children, ...props }) => (
  <BulmaSection {...props}>
    {children}
  </BulmaSection>
);

export default Section;
