import React, { useState } from 'react'
import { Navbar } from 'react-bulma-components/full';
import { FiHeadphones, FiUsers, FiStar, FiFacebook, FiInstagram, FiYoutube } from 'react-icons/fi';
import Container from '../Container'
import Link from '../Link'
import Logo from './images/logo.jpg'


export const Navigation = () => {
  const [open, setOpen] = useState(false);

  return (
    <Navbar color="info" active={open} transparent={true}>
      <Container>
        <Navbar.Brand>
          <Navbar.Burger active={open ? 'active' : undefined} onClick={() => setOpen(!open)} />
        </Navbar.Brand>
        <Navbar.Menu active={open ? 'active' : undefined}>
          <Navbar.Container>
            <Navbar.Item renderAs={Link} to="/podcasts"><FiHeadphones /> &nbsp; Podcasts</Navbar.Item>
            <Navbar.Item renderAs={Link} to="/team"><FiUsers /> &nbsp; Team</Navbar.Item>
            <Navbar.Item renderAs={Link} to="/guests"><FiStar /> &nbsp; Guests</Navbar.Item>
          </Navbar.Container>
          <Navbar.Container position="end">
            <Navbar.Item target="_blank" href="https://www.facebook.com/TheBeat139"><FiFacebook style={{strokeWidth:1,fontSize:30}} /></Navbar.Item>
            <Navbar.Item target="_blank" href="https://www.youtube.com/channel/UCT78LXDZ5WRCffKALJ12gwg/videos"><FiYoutube style={{strokeWidth:1,fontSize:30}} /></Navbar.Item>
            <Navbar.Item target="_blank" href="https://www.instagram.com/TheBeat139"><FiInstagram style={{strokeWidth:1,fontSize:30}} /></Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </Container>
    </Navbar>
  );
}

export default Navigation;
