import React from 'react'
import { Pagination, Tile } from "react-bulma-components/full";
import Layout from '../../components/Layout'
import SearchBox from '../../components/SearchBox'
import Section from '../../components/Section'
import Text from '../../components/Text'
import VideoCard from '../../components/VideoCard'
import { data } from '../../libs/yt'


export const PodcastItem = ({ video }) => (
  video ? <Tile kind="parent" size={4}><Tile kind="child"><VideoCard video={video} /></Tile></Tile> : ''
);

export const PodcastsView = () => {
  const ITEMS_PER_PAGE = 3*3;
  const [ state, setState ] = React.useState({ currentPage: 1, keywords: '' });
  const { currentPage, keywords } = state;
  let idx = (currentPage - 1) * ITEMS_PER_PAGE;

  const podcasts = keywords.length < 2 ? data : data.filter(p => p.video_title.toLowerCase().indexOf(keywords.toLowerCase()) >= 0);
  const TOTAL_PAGES = Math.ceil(podcasts.length / ITEMS_PER_PAGE);

  return (
    <Layout title="Podcasts" subtitle="Latest Episodes">
      <SearchBox placeholder="Search podcasts" keywords={keywords} onChange={e => { setState({ keywords: e.target.value, currentPage: 1 }) }} />
      <Pagination onChange={(page) => setState({ currentPage: page > TOTAL_PAGES ? 1 : page, keywords })}
                  className="is-rounded"
                  current={currentPage || 1}
                  total={TOTAL_PAGES}
                  delta={0}
                  showPrevNext={true} />
      <Section style={{ paddingLeft:0, paddingRight:0 }}>
        {!podcasts.length && <Text renderAs="p" size={5}>No podcasts found for "{keywords}"</Text>}
        <Tile kind="ancestor">
          <PodcastItem video={podcasts[idx]} />
          <PodcastItem video={podcasts[idx+1]} />
          <PodcastItem video={podcasts[idx+2]} />
        </Tile>
        <Tile kind="ancestor">
          <PodcastItem video={podcasts[idx+3]} />
          <PodcastItem video={podcasts[idx+4]} />
          <PodcastItem video={podcasts[idx+5]} />
        </Tile>
        <Tile kind="ancestor">
          <PodcastItem video={podcasts[idx+6]} />
          <PodcastItem video={podcasts[idx+7]} />
          <PodcastItem video={podcasts[idx+8]} />
        </Tile>
      </Section>
      <Pagination onChange={(page) => setState({ currentPage: page > TOTAL_PAGES ? 1 : page, keywords })}
                  className="is-rounded"
                  current={currentPage || 1}
                  total={TOTAL_PAGES}
                  delta={100}
                  showPrevNext={true} />
    </Layout>
  );
}

export default PodcastsView;
