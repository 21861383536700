import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store from './redux/store';
import PodcastsView from './views/PodcastsView';
import TeamView from './views/TeamView';
import GuestsView from './views/GuestsView';
import AdvertisementsView from './views/AdvertisementsView';


ReactGA.initialize('UA-147256874-1');

const TrackedPage = (Component) => class GoogleAnalyticsWrapper extends React.Component {
  componentDidMount() {
    const PAGE_URL = window.location.pathname + window.location.search;
    const PAGE_TITLE = Component.name || Component.displayName || 'Untitled Page';
    const PAGE_ACTION = 'beat139-page-load';

    ReactGA.pageview(PAGE_URL);
    ReactGA.event({ category: PAGE_TITLE, action: PAGE_ACTION });
  }

  render() {
    return (<Component {...this.props} />);
  }
};

const App = () => (
  <Provider store={Store}>
    <Router>
      <Switch>
        <Route exact path="/" component={TrackedPage(GuestsView)} />
        <Route exact path="/podcasts" component={TrackedPage(PodcastsView)} />
        <Route exact path="/team" component={TrackedPage(TeamView)} />
        <Route exact path="/guests" component={TrackedPage(GuestsView)} />
        <Route exact path="/advertisements" component={AdvertisementsView} />
      </Switch>
    </Router>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));
