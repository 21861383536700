import CONSTANTS from './constants';


const initialState = {
  // model: {},
  // list: [],
  // instance: null,
};

export default (state=initialState, action) => {
  const { payload } = action;

  switch(action.type) {
    case CONSTANTS.GUESTS_OPTIONS:
      return { ...payload };

    case CONSTANTS.GUESTS_LIST:
      return { ...payload };

    case CONSTANTS.GUESTS_POST:
      return { ...payload };

    case CONSTANTS.GUESTS_GET:
      return { ...payload };

    case CONSTANTS.GUESTS_PATCH:
      return { ...payload };

    case CONSTANTS.GUESTS_PUT:
      return { ...payload };

    case CONSTANTS.GUESTS_DELETE:
      return { ...payload };

  }

  return state;
};

