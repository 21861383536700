import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import guests from '../states/guests';


const reducers = combineReducers({
  guests: guests.reducer
});

// @see https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const Store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default Store;
