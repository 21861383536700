import React from 'react'
import Layout from '../../components/Layout'
import Section from '../../components/Section'


export const GoogleAd = ({ adSlot="9609872922", width=300, height=250 }) => (
  <div>
    <ins className="adsbygoogle"
         style={{ border:'1px solid #CCC', display:'inline-block', width, height }}
         data-ad-client="ca-pub-9526948684499099"
         data-ad-slot={adSlot} />
    <script>
      (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
  </div>
);

export const AdvertisementsView = () => {
  return (
    <Layout title="Advertisements" subtitle="For Testing Purposes Only">
      <Section style={{ paddingLeft:0, paddingRight:0 }}>
        <GoogleAd adSlot="9609872922" width={300} height={250} />
        <hr />
        <GoogleAd adSlot="8284989440" width={728} height={90} />
      </Section>
    </Layout>
  );
}

export default AdvertisementsView;
