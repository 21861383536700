import React, { useState } from 'react'
import { FiPlay, FiPlayCircle } from 'react-icons/fi';
import { Card, Modal, Tile } from "react-bulma-components/full";
import Popup from '../Popup'
import Text from '../Text'
import YouTube, { YouTubeThumb } from '../YouTube'


export const PlayButton = ({ color='#209cee',size=150, ...props }) => (
  <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',alignContent:'center',position:'absolute',height:'80%',width:'100%'}}>
    <FiPlay style={{color,cursor:'pointer',fontSize:size,strokeWidth:1,zIndex:1,fill:'#FFFA'}} {...props} />
  </div>
);

export const VideoPopup = ({ video, ...props }) => (
  <Popup width="90%" height="90%" title={video.video_title} {...props}>
    <YouTube autoplay={true} videoId={video.video} title={video.video_title} height="100%" width="100%" />
  </Popup>
);

export const VideoCard = ({ video, color='#209CEE', size=150, style, ...props }) => {
  const [ openState, setOpen ] = useState(false);

  return (
    <Card style={{ ...style, borderRadius:5, overflow:'hidden', position:'relative' }} {...props}>
      <PlayButton color={color} size={size} onClick={() => setOpen(true)} />
      {openState && <VideoPopup video={video} open={openState} onClose={() => setOpen(false)} />}
      <YouTubeThumb key={video.video} videoId={video.video} alt={video.video_title} />
      <Card.Content>
        <Text size={5} weight="normal">{video.video_title}</Text>
      </Card.Content>
    </Card>
  );
};

export default VideoCard;
