import React from 'react'
import { FiSend, FiEye, FiCrosshair } from 'react-icons/fi';
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { Box, Image, Menu, Navbar, Tile } from 'react-bulma-components/full';
import PicOfDoc from './images/doc.jpg'
import PicOfVeto from './images/veto.jpg'
import PicOfCallista from './images/callista.jpg'
import PicOfKeithCash from './images/keith.jpg'


export const FeatureBox = ({ icon, title, color, children, ...props }) => (
  <Tile kind="parent" size={4} {...props}>
    <Tile kind="child" renderAs={Box} color={color} style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center'}}>
      <Text style={{display:'flex'}}>
        <Text size={2} renderAs={icon} /> &nbsp; {title}
      </Text>
      <div>{children}</div>
    </Tile>
  </Tile>
);

export const ImageBanner = ({ children }) => (
  <div style={{zIndex:1,padding:'0 1em',position:'absolute',bottom:0,left:0,background:'rgba(0,0,0,.9)',display:'flex',justifyContent:'center', alignItems:'center',alignContent:'center',height:75,width:"100%"}}>
    <Text color="white" size={4}>
      {children}
    </Text>
  </div>
);

export const ImageBox = ({ src, name, title, ...props }) => (
  <Tile kind="parent" size={4}>
    <Tile renderAs={Box} kind="child" style={{position:'relative'}}>
      {src && <Image size="2by3" src={src} alt={title} title={`The Beat 139 hosts: ${title}`} {...props} />}
      {name && <ImageBanner>{name}</ImageBanner>}
    </Tile>
  </Tile>
);

export const TeamView = () => (
  <Layout title="Team" subtitle="Meet our Hosts!">
    <Tile kind="ancestor">
      <ImageBox src={PicOfDoc} name="Doc, Founder + Host" />
      <ImageBox src={PicOfVeto} name="Donveto, Founder + Host" />
      <ImageBox src={PicOfKeithCash} name="Keith Cash, Brand Ambassador" />
    </Tile>
    <br /><br />
    <Tile kind="ancestor">
      <ImageBox name="Jerry Riddick, Marketing" /><br />
      {/* <ImageBox name="Jamal Dubar, Business Development" /><br /> */}
      <ImageBox name="Joshua Cruz, Camera-man" /><br />
      <ImageBox name="Earl Shorty, Producer" /><br />
    </Tile>
    <br /><br />
    <Tile kind="ancestor">
      <ImageBox name="Ted Thompson, Advertising" /><br />
      <ImageBox name="Rhonda Super, Admin Assistant" /><br />
      <ImageBox name="Cley Cless, Tech Consultant" /><br />
    </Tile>
    <Tile kind="ancestor">
      <FeatureBox icon={FiSend} title="Our Mission" color="info">
        <p>
          The Beat 139 is a Harlem-based Podcast Company whose mission is
          to provide a platform for artists, entertainers, entrepreneurs, promoters, and others.
          <br /><br />
          The Beat 139 has been established to increase the awareness and encourage the growth
          and opportunities of a broadly diversified audience in the New York area.
        </p>
      </FeatureBox>
      <FeatureBox icon={FiEye} title="Our Vision">
        <p>
        We envision helping people increase their visibility through a structured, positive,
        and professional format that enables them to develop long-term success.
        </p>
      </FeatureBox>
      <FeatureBox icon={FiCrosshair} title="Our Goals">
        <p>
          We aim to become the premier local podcast show, based on viewership, within two years.
          <br /><br />
          We also plan to sign at least 100 guest appearances within the first year, and reach profitability within two years.
        </p>
      </FeatureBox>
    </Tile>
  </Layout>
);

export default TeamView;
